import React, { Fragment, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import emailjs from "@emailjs/browser";



import "./contact.css";
const Result = () => {
  return <p>Your message is sent successfully. We will contact You soon.</p>;
};

const Contact = () => {
  const [result, showResult] = useState();

  const customerSelect = useRef(null);
  const helpSelect = useRef(null);
  const methodSelect = useRef(null);
  const bsContactSelect = useRef(null);
  const handleSubmit = (event) => {
     event.preventDefault();
  
    const templateParams = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      address: event.target.address.value,
      code: event.target.code.value,
      customer:customerSelect.current.value,
      help:helpSelect.current.value,
      method:methodSelect.current.value,
      bsContact:bsContactSelect.current.value,
      message: event.target.message.value,
};
    emailjs.send('service_709lh7m', 'template_sybuapr', templateParams, 'LGhTVh4D3i17nlA6h')

    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );

  event.target.reset();
  showResult(true);
}


  

  
  return (
    // <div className="contact">
    <Container className=" mt-[4rem] mb-5">
      <Fragment>
        <section className="contact">
          <div className="contact-heading">
            <h2>Contact-Us To get free estimate</h2>
          </div>
          <div className="containerc">
            <div className="row1">
              <div className="column">
                <div className="contact-widget">
                  <div className="contact-widget-item">
                    <div className="cicon">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="ctext">
                      <h4>Address</h4>
                      <p>
                        Shivaji putala, 1st Floor, Flat No. 1, Laxmi Heights,
                        Jadhav Galli, <br /> igatpuri, Ghoti, Nashik,
                        Maharashtra, 422402
                      </p>
                    </div>
                  </div>
                  <div className="contact-widget-item">
                    <div className="cicon">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <div className="ctext">
                      <h4>Contact Us</h4>
                      <a
                        className="text-yellow-700 font-bold"
                        href="https://wa.me/919371779757"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +91 93717 79757
                      </a>
                    </div>
                  </div>
                  <div className="contact-widget-item">
                    <div className="cicon">
                      <i class="fa-regular fa-envelope"></i>
                    </div>
                    <div className="ctext">
                      <h4>Email</h4>
                      <a
                        href="mailto:amsoimpex@gmail.com"
                        className="text-yellow-700 font-bold"
                      >
                        amsoimpex@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <TextField
                      className="formmrug"
                      label=" Full Name"
                      name="name"
                      margin="normal"
                      required
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <TextField
                      className="formmrug"
                      label="Email"
                      name="email"
                      margin="normal"
                      required
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <TextField
                      className="formmrug"
                      label="Phone"
                      name="phone"
                      margin="normal"
                      required
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <TextField
                      className="formmrug"
                      label="State"
                      name="address"
                      margin="normal"
                      required
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <TextField
                      className="formmrug"
                      label="ZIP code"
                      name="code"
                      margin="normal"
                      required
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <h3 required className=" mt-3 text-xl ">
                        Customer Type*
                      </h3>
                      <select
                       
                        ref={customerSelect}
                        className="select select-bordered w-full max-w-xs mt-3 bg-base-200"
                      >
                        <option disabled selected>
                          Homeowner
                        </option>
                        <option>Homeowner</option>
                        <option>Builder</option>
                        <option>Designer</option>
                        <option>Dealer</option>
                      </select>
                      <h3 required className=" mt-3 text-xl ">
                        How Can We Help You? *
                      </h3>
                      <select
                       
                        ref={helpSelect}

                        className="select select-bordered w-full max-w-xs mt-3 bg-base-200"
                      >
                        <option disabled selected>
                          I Need help finding a door
                        </option>
                        <option>I Need help finding a door</option>
                        <option>I Want to be a Wholesaler</option>
                        <option>I Want a Custom Door</option>
                        <option>I Need Assitance with my Door(Support)</option>
                        <option>Other</option>
                      </select>
                      <h3 required className=" mt-3 text-xl ">
                        Contact Method*
                      </h3>
                      <select
                        
                        ref={methodSelect}

                        className="select select-bordered w-full max-w-xs mt-3 bg-base-200"
                      >
                        <option disabled selected>
                          Text Message
                        </option>
                        <option>Text Message</option>
                        <option>Phone Call</option>
                        <option>Email</option>
                      </select>
                      <h3 required className=" mt-3 text-xl ">
                        Best Time to Contact*
                      </h3>
                      <select
                        
                        ref={bsContactSelect}

                        className="select select-bordered w-full max-w-xs mt-3 bg-base-200"
                      >
                        <option disabled selected>
                          Morning
                        </option>
                        <option>Morning</option>
                        <option>Lunch</option>
                        <option>Afternoon</option>
                        <option>Evening</option>
                        <option>Weekend</option>
                        <option>Anytime</option>
                      </select>
                    </div>

                    <textarea
                      name="message"
                      className="textarea textarea-bordered mt-[2rem] bg-base-100"
                      placeholder="Any other details or questions?
"
                    ></textarea>
                    <br />
                    <p className="mrugank5 mt-3">Photo of your area</p>
                    <input type="file" className="file-input mb-2 mt-4 " />
                    <br />
                    <button type="submit"  className=" py-4 px-3 rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold">
                      Submit
                    </button>
                   

                    <div className="row">{result ? <Result /> : null}</div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row">
            
              <div className="map ">
                <div className="contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3755.9350052842524!2d73.62540297509308!3d19.715391081625302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd856060f2fa99%3A0x584656e716869710!2sJadhav%20Galli!5e0!3m2!1sen!2sin!4v1673024865608!5m2!1sen!2sin"
                    width="95%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    </Container>
  );
};

export default Contact;
