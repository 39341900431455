import React from "react";
import year from "./../../assets/10year.png";
import one from "./../../assets/qua.png";
import gauge from "./../../assets/gauge.png";
import impact from "./../../assets/impact.png";
import Drawings from "./../Drawings/Drawings";
import "animate.css";

const Content = () => {
  return (
    <>
      <style>
        {`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `}
      </style>
      <div className="content mt-[0.5rem] lg:mt-[-10rem]">
        <div className=" mt-10  py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center sm:text-left">
            <h1 className="text-4xl font-bold text-black justify-center items-center flex  ">
              Finely Handcrafted
            </h1>
            <p className="mt-3 text-xl text-black">
              {" "}
              We focus on extremely fine crafted metal works and the way to meet
              your desired form to make it suit perfectly for your house or any
              time of architecture.The skills of worker and their compation
              towards artistic form is considered on priority We promise you to
              fulfill your expectations.
            </p>
          </div>
        </div>

        <div class="wpb_text_column wpb_content_element ">
          <div class="wpb_wrapper ">
            <h1 className="text-4xl font-bold bg">Design and Installation</h1>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-8 ">
          <div class="p-4 ">
            <div class="q_icon_with_title medium circle center">
              <div class="icon_holder">
                <span class="qode_iwt_icon_holder fa-stack fa-3x">
                  <img src={one} alt="" />
                </span>
              </div>
              <div class="icon_text_holder mt-6 hover:bg-orange-300 hover:rounded-lg hover:mt-[0.5rem] hover: py-5 hover: px-3 ">
                <div class="icon_text_inner">
                  <h5 class="font-bold font-serif text-xl animate-fade-in">
                    Elegant Design
                  </h5>
                  <p className="font-medium font-serif animate-fadeIn">
                  Amso Iron Works has the capability to craft a stunning design that complements your home's theme, offering custom fabrication for Urban, Milano, and Wrought Iron products to turn your dreams into reality.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4">
            <div class="q_icon_with_title medium circle center">
              <div class="icon_holder">
                <span
                  data-icon-type="circle"
                  class="qode_iwt_icon_holder fa-stack fa-3x"
                >
                  <img src={gauge} alt="" />
                </span>
              </div>
              <div class="icon_text_holder mt-6 hover:bg-orange-300 hover:rounded-lg hover:mt-[0.5rem] hover: py-5 hover: px-3 ">
                <div class="icon_text_inner">
                  <h5 class=" font-bold font-serif text-xl">
                    Quality Craftsmanship
                  </h5>
                  <p className="font-medium font-serif">
                    Hand forged iron doors combine new world technology with old
                    world artistry. Our doors are built to meet the highest
                    standards including hurricane code (HVHZ) and NFRC energy
                    codes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4">
            <div class="q_icon_with_title medium circle center">
              <div class="icon_holder">
                <span class="qode_iwt_icon_holder fa-stack fa-3x">
                  <img src={impact} alt="" />
                </span>
              </div>
              <div class="icon_text_holder mt-6 hover:bg-orange-300 hover:rounded-lg hover:mt-[0.5rem] hover: py-5 hover: px-3 ">
                <div class="icon_text_inner">
                  <h5 class="font-bold font-serif text-xl">
                    Regional Specifications
                  </h5>
                  <p className="font-medium font-serif">
                  Amso Iron Works recognizes the variations in local building codes across our diverse markets. We've successfully designed and certified impact-rated products for regions that demand superior hurricane protection. Additionally, we've introduced thermal break technology to offer improved insulation for colder climates.





                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center sm:text-left">
            <h1 className="text-4xl font-bold text-black justify-center items-center flex">
              Your Thoughts Into Reality
            </h1>
            <p className="mt-3 text-xl text-black">
              {" "}
              Select a Standard configuration or draw your own.The possibilities
              are only limited by your imagination. We accept any type of
              reference ideas and <b> willing to turn them into reality.</b>
            </p>
          </div>
        </div>

        <Drawings />
      </div>
    </>
  );
};

export default Content;
