
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper";
import Img1 from "../../assets/Imgc1.jpg";
import Img2 from "../../assets/slider2.jpg";

import Img3 from "../../assets/Imgc2.jpeg";

const Slides = [
  {
    title: "Handcrafted Iron Doors",
    bg: Img1,
    btnText: "Find your Classic iron door",
  },
  {
    title: "Quality Craftsmenship and Relentless attention",
    bg: Img2,
    btnText: "Double Doors",
  },
  {
    title: "Easily Customizable",
    bg: Img3,
    btnText: "Find your Contemporary Iron Door",
  },
];
const Carousel = () => {
  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect={"fade "}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      className="heroSlider h-[400px] lg:h-[660px] bg"
    >
      {Slides.map((slide, index) => {
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className="h-full mt-[4rem] bg-pink-400 relative flex justify-center items-center"
            key={index}
          >
            <div className="z-20 text-white text-center">
              <div className="uppercase font-tertiary tracking-[5px]  mb-5">
                Your Thoughts into Reality
              </div>
              <h1 className="text-[32px] z-20  uppercase tracking-[2px] max-w-[920px] lg:text-[68px] font-serif leading-tight mb-6">
              {title}
            </h1>
            <a className="py-5 px-5 rounded-lg bg-yellow-600 text-lg w- text-white font-bold" href="/products">{btnText}</a>
            </div>
            
            <div className="absolute top-0 w-full h-full ">
              <img className="object-cover h-full w-full" src={bg} alt="" />
            </div>

            <div className="absolute h-full w-full bg-black/40"></div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
