import React, { useEffect, useState,useRef } from "react";
import { useParams } from "react-router-dom";
import s1 from "../../assets/cutouts clean/gate/33.png";
import s2 from "../../assets/cutouts clean/gate/34.png";
import s3 from "../../assets/cutouts clean/gate/35.png";
import s4 from "../../assets/cutouts clean/gate/36.png";
import s5 from "../../assets/cutouts clean/gate/37.png";


import emailjs from 'emailjs-com';

const Result = (props) => {
  return <p>Your message is sent successfully. We will contact You soon.</p>;
};


const IronProducts = () => {
  const [result, showResult] = useState();

  const widthSelect = useRef(null);
  const heightSelect = useRef(null);
  const topStyleSelect = useRef(null);
  const pushPullSelect = useRef(null);
const handleSubmit = (event) => {
event.preventDefault();
  
    const templateParams = {
      name: event.target.name.value,
      email: event.target.email.value,
      width: widthSelect.current.value,
      height: heightSelect.current.value,
      topStyle: topStyleSelect.current.value,
      pushPull: pushPullSelect.current.value,
    };
    emailjs.send('service_709lh7m', 'template_6zqp4cb', templateParams, 'LGhTVh4D3i17nlA6h')

    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );

  event.target.reset();
  showResult(true);
}
    const { gateId } = useParams();
    const [product, setProduct] = useState(null);
    useEffect(() => {
      // Simulating an asynchronous API call to fetch the product data
      const fetchProductData = async () => {
        try {
          // Mock product data array
          const productData = [
           
         
  
                {
                  id: 1,
                  name: "Iron Gate 1",
                  
                  image:s1
                },
                {
                  id: 2,
                  name: "Iron Gate 2",
                  
                  image: s2,
                },
            
                {
                  id: 3,
                  name: "Iron Gate 3",
                  image:s3,
                },
                {
                  id: 4,
                  name: "Iron Gate 4",
                  image:s4,
                },
                {
                  id: 5,
                  name: "Iron Gate 5",
                  image: s5,
                },
            ]
  
            
          
  
          // Find the product with the matching productId
          const selectedProduct = productData.find(
            (product) => product.id === parseInt(gateId)
          );
  
          if (selectedProduct) {
            // Set the selected product in the state
            setProduct(selectedProduct);
          } else {
            // Handle product not found
            console.log("Product not found");
          }
        } catch (error) {
          // Handle error if any
          console.error("Error fetching product data:", error);
        }
      };
  
      // Call the fetchProductData function
      fetchProductData();
    }, [gateId]);
  
    if (!product) {
      return <div>Loading...</div>;
    }
  return (
    <div className="flex flex-col lg:flex-row items-center lg:items-start mt-[7rem]">
    <div className="w-full lg:w-1/2 p-4">
      <img className="lg:h-[45rem]" src={product.image} alt={product.name} />
    </div>
    <div className="w-full font-bold lg:w-1/2 p-4 mt-[8rem]">
      <h2 className="text-[30px]">{product.name}</h2>
      <p>
        <p className="text-gray-700 mb-4">
          <h3 className="text-md font-semibold">Enter the Details:</h3>
         <form  onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center items-center ">

         <input type="text" required name="name" placeholder="Full Name" className="input input-bordered w-full max-w-xs " /> <br />
         <input type="email" required name="email" placeholder="Email Id" className="input input-bordered w-full max-w-xs " />
          </div>


        
         
    <h3 className="mt-3">Enter the width:</h3>
    <select ref={widthSelect} className="select select-bordered w-full max-w-xs">
    <option disabled selected>
                30 Inch Doorway
              </option>
              <option>30 Inch Doorway</option>
              <option>32 Inch Doorway</option>
              <option>34 Inch Doorway</option>
              <option>36 Inch Doorway</option>
              <option>40 Inch Doorway</option>
              <option>48 Inch Doorway</option>
              <option>56 Inch Doorway</option>
              <option>60 Inch Doorway</option>

    </select>

    <h3 className="mt-3">Enter the height:</h3>
    <select ref={heightSelect} className="select select-bordered w-full max-w-xs mt-3">
    <option disabled selected>
                {" "}
                80 Inch tall Doorway
              </option>
              <option> 80 Inch tall Doorway</option>{" "}
              <option>82 Inch tall Doorway</option>{" "}
              <option>84 Inch tall Doorway</option>
              <option>90 Inch tall Doorway</option>
              <option>96 Inch tall Doorway</option>

    </select>

    <h3 className="mt-3">Top Style:</h3>
    <select ref={topStyleSelect} className="select select-bordered w-full max-w-xs mt-3">
    <option disabled selected>
                Square Top
              </option>
              <option> Square Top</option>
              <option>Round Top</option>
              <option>7 Inch tall Eyebrow arch</option>
              <option>11 Inch tall Eyebrow arch</option>

    </select>

    <h3 className="mt-3">Push or Pull top Open:</h3>
    <select ref={pushPullSelect} className="select select-bordered w-full max-w-xs mt-3">
    <option disabled selected>
                {" "}
                Pull to Open
              </option>
              <option> Pull to Open</option> <option>Push to Open</option>

    </select>
<br />


    <button type="submit" className="mt-[2rem] cursor-pointer bg-yellow-600 hover:bg-black text-white font-semibold  py-2 px-4 border border-yellow-500 hover:border-transparent rounded">
      Get a Quote
    </button>
    <div className="row">{result ? <Result /> : null}</div>
  </form>
        </p>
      </p>
    </div>
  </div>
  )
}

export default IronProducts