import React from 'react'
import Carousel from '../../components/Carousel/Carousel'
import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts'
import Content from '../../components/Content/Content'
import ContactCon from '../../components/contactCon/ContactCon'
import Reviews from "../../components/Reviews/Reviews";
import data from "./../../components/Reviews/data.json";


const Home = () => {
  return (
    <div >
      
        <Carousel/>
        <FeaturedProducts/>
        <Content/>
        
        <ContactCon/>
        <Reviews testimonialData={data} />
    </div>
  )
}

export default Home