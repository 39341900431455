import React from "react";

import "./featuredproducts.css";
import fp1 from "../../assets/winecellar3.jpg";

import fp3 from "../../assets/15.png";
import fp4 from "../../assets/31.png";

import fp6 from "../../assets/cutouts clean/gate/36.png";

const FeaturedProducts = () => {
  return (
    <>
      <div class=" min-h-screen relative  mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full">
        <div class="hero-content flex-col lg:flex-row">
          <img
            src={fp3}
            alt="prod"
            class="max-w-sm rounded-lg shadow-2xl md: ml-0 sm:ml-0"
          />
          <div class="lg:pl-8">
            <h1 class="text-3xl md:text-5xl font-bold">
              Single Doors and&nbsp;
              <br class="hidden md:inline" />
              Double Doors
            </h1>
            <p class="py-6 font-semibold">
              Wrought iron doors come in two categories, single and double
              doors, and can be customized based on various designs and styles.
              The choice of single or double doors depends on personal
              preference, entryway size, and security needs. So, if you're
              looking for sturdy and stylish doors, wrought iron single or
              double doors could be an excellent option for you.
            </p>
            <div className="flex items-center justify-center">
              <label
                htmlFor="my-modal-6"
                className=" py-4 px-3 rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold"
              >
                Explore More
              </label>

              <input type="checkbox" id="my-modal-6" className="modal-toggle" />
              <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <a
                        href="/singleproducts"
                        className="label-text font-bold"
                      >
                        Single Doors
                      </a>
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <a
                        href="/doubleproducts"
                        className="label-text font-bold"
                      >
                        Double Doors
                      </a>
                    </label>
                  </div>
                  <div className="modal-action">
                    <label htmlFor="my-modal-6" className="btn">
                      Close
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl lg:mt-[-8rem]">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <a href="/winecellar">
            {" "}
            <img
              src={fp1}
              alt="prod"
              className="max-w-sm rounded-lg h-[35rem] shadow-2xl"
            />
          </a>
          <div>
            <h1 className="text-5xl font-bold">Wine Cellar Doors</h1>
            <p className="py-7 px-3 font-semibold">
              {" "}
              Discover the perfect wine cellar doors to elevate your wine
              storage to new heights. Our customizable iron doors are both
              elegant and secure, providing excellent visibility while
              maintaining climate control for long-term cellaring. Choose from a
              range of single or double entry doors to complement any size or
              design of your cellar. Our luxury home wine cellar doors are sure
              to impress, and our custom creations, such as grape leaf designs,
              offer a unique touch. Browse our selection online or contact us to
              create your perfect wine cellar door.
            </p>
            <a
              href="/winecellar"
              className="  py-4 px-3 rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold"
            >
              Explore More
            </a>
          </div>
        </div>
      </div>
      <div class="min-h-screen relative  mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full lg:mt-[-8rem]">
        <div class="hero-content flex-col lg:flex-row">
          <a href="/customization">
            <img
              src={fp4}
              alt="prod"
              class="max-w-sm rounded-lg shadow-2xl md: ml-0 sm:ml-0"
            />
          </a>
          <div class="lg:pl-8">
            <h1 class="text-3xl md:text-5xl font-bold">Customized Doors</h1>
            <p class="py-6 font-semibold">
              {" "}
              Looking for a custom-made configuration that fits your unique
              needs and preferences? Look no further than our team of experts!
              Whether you're seeking a standard configuration or something more
              unique, we've got you covered. Our talented designers and
              technicians are skilled at turning your ideas into reality, no
              matter how complex or unconventional they may be. From innovative
              layouts to cutting-edge technology, we can create the perfect
              configuration for your home or business. So why wait? Contact us
              today to get started on your dream configuration!
            </p>
            <a
              href="/customization"
              class=" py-4 px-3 rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold"
            >
              Explore More
            </a>
          </div>
        </div>
      </div>
      <div className="min-h-screen relative  mx-auto lg:py-32 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full lg:mt-[-10rem]">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <a href="irongates">
            {" "}
            <img
              src={fp6}
              alt="prod"
              className="max-w-sm rounded-lg shadow-2xl"
            />
          </a>
          <div>
            <h1 className="text-5xl font-bold">
              {" "}
              Iron railings, Handrails, Gates, and Fences
            </h1>
            <p className="py-6 font-semibold">
              {" "}
              We specialize in crafting high-quality iron railings, handrails,
              gates, and fences that not only enhance the curb appeal of your
              home or business but also provide added security. Whether you have
              a specific design in mind or need help creating one, our sales
              professionals will work with you to ensure that every railing,
              gate, and fence is fabricated to perfection and up to our
              company's standards. Our iron gates are durable and create a
              harmonious balance with the overall design of your property, while
              our iron fences offer functional and decorative solutions that
              limit access to your private property. Choose us for our excellent
              craftsmanship and commitment to delivering top-quality products.
            </p>
            <a
              href="/irongates"
              className=" py-4 px-3 rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold"
            >
              Explore More
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedProducts;
