// import React from "react";
// import con1 from "./../../assets/Contact.jpg";
// import "./contactcon.css";

// const ContactCon = () => {
//   return (
//     <div className="">
//     <div className="hero-image">
//     <div className="hero-text">
//         <h1>CONTACT US FOR YOUR SPECIFIC REQUIREMENT </h1>
//         <p>ITS OUR PLEASURE TO DELIVER  </p>
//         <p>THE EXACT PRODUCT YOU HAVE </p>
//         <p>IMAGINED...</p>

//         <a href="#" className="hero-btn">Contact Us</a>
//     </div>
// </div>
// </div>
//   );
// };

// export default ContactCon;
import React from "react";
import "./contactcon.css";

const ContactCon = () => {
  return (
    <>
   
    <div class="hero-image4">
      <div class="hero-text">
        <h1>CONTACT US FOR YOUR SPECIFIC REQUIREMENT </h1>
        <p>Its Our Pleasure to Deliver </p>
        <p>The Exact Product You Have  </p>
        <p>Imagined...</p>
      </div>
      
    </div>
    <div>
    <a
      href="/contact"
      className="py-3 px-3 mb-[-2rem] rounded-lg bg-yellow-600 text-lg hover:bg-black text-white font-bold"
    >
      Contact Us
    </a>
  </div>
  </>
  );
};

export default ContactCon;
