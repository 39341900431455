import React from 'react'

import "./IronGates.css";
import { Link } from "react-router-dom";
import s1 from "../../assets/cutouts clean/gate/33.png";
import s2 from "../../assets/cutouts clean/gate/34.png";
import s3 from "../../assets/cutouts clean/gate/35.png";
import s4 from "../../assets/cutouts clean/gate/36.png";
import s5 from "../../assets/cutouts clean/gate/37.png";

const IronGates = () => {
    const products = [
  
        {
          id: 1,
          name: "Product 1",
          
          image:s1
        },
        {
          id: 2,
          name: "Product 2",
          
          image: s2,
        },
    
        {
          id: 3,
          name: "Product 3",
          image:s3,
        },
        {
          id: 4,
          name: "Product 4",
          image:s4,
        },
        {
          id: 5,
          name: "Product 5",
          image: s5,
        },
    ]
  return (
    <>
    <div className="sp mt-[6rem]">
      <section className="spc">
        <div className="cards grids">
          {products.map((product) => (
            <div className="spcard" key={product.id}>
              <div className="spimages1">
                <Link to={`/irongates/${product.id}`}>
                  <img src={product.image} alt={product.name} />
                </Link>
              </div>
              <div className="spcard-conten">
              <h1 className="sp-heading">{product.name}</h1>
              </div>
            </div>
          ))}
        </div>
      </section>
      </div>

     
</>
  )
}

export default IronGates