
import React from "react";
import "./WineCellar.css";
import { Link } from "react-router-dom";

import s1 from "../../assets/winecellar3.jpg";
import s2 from "../../assets/winecellar4.jpg";
import s3 from "../../assets/winecellar5.jpg";
import s4 from "../../assets/winecellar6.jpg";

import s6 from "../../assets/winecellar8.jpg";
import s7 from "../../assets/winecellar9.jpg";







const WineCellar = () => {
  const products = [
  
    {
      id: 1,
      name: "Wine Cellar 1",
      
      image:s1
    },
    {
      id: 2,
      name: "Wine Cellar 2",
      
      image: s2,
    },

    {
      id: 3,
      name: "Wine Cellar 3",
      image:s3,
    },
    {
      id: 4,
      name: "Wine Cellar 4",
      image:s4,
    },
   
    {
      id: 6,
      name: "Wine Cellar 6",
      image: s6,
    },
    {
      id: 7,
      name: "Wine Cellar 7",
      image: s7,
    },
    


    // Add more products for other categories
  ];
  return (
    <>
    <div className="spdw mt-[5rem]">
      <section className="spc">
        <div className="gridsdw">
          {products.map((product) => (
            <div className="spcardw" key={product.id}>
              <div className="winecellarbox  h-[40rem]" >
                <Link to={`/winecellar/${product.id}`}>
                  <img  className="" src={product.image} alt={product.name} />
                </Link>
              </div>
              <div className="spcardw-content">
              <h1 className="sp-heading">{product.name}</h1>
              </div>
            </div>
          ))}
        </div>
      </section>
      </div>


</>
  
  );
};

export default WineCellar;
