import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";

const Customization = () => {
  return (
    <>
      <Container className=" mt-[4rem]  mb-5">
        <Fragment>
          <section className="contact mt-3">
            <div className="contact-heading">
              <h2>Do-it-yourself front door projects</h2>
            </div>
          </section>
        </Fragment>
      </Container>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8 mt-[-5rem] items-center justify-center">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-4xl font-bold text-black t">
            How to Measure Front Doors
          </h1>
          <p className="mt-3 text-xl text-black flex flex-wrap justify-center">
            If you're interested in learning how to measure your front door,
            follow along with our step-by-step instructions for a precise fit.
          </p>
        </div>
      </div>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8  items-center justify-center">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-3xl font-bold text-black t">
            To accurately replace your front entry door, follow these steps:
          </h1>
          <p className="mt-3 text-xl text-black bg-slate-100 px-6 text-justify">
            <span className="fonr-bold"> 1.Measure the width:</span> <br />

            Use a tape measure to measure the width of the door slab or existing
            door from edge to edge. Most standard front doors are approximately
            36 inches wide. Measure the height: Use a tape measure to measure
            the height of the door slab or existing door from top to bottom. The
            typical height for a front door is around 80 inches. <br />
            Additional tips: <br />
            Measure the door slab itself, excluding any surrounding trim or
            frame. Take measurements at multiple points (top, middle, and
            bottom) to account for variations. Record the width and height in
            inches. With accurate measurements, you can consult professionals or
            browse online to find the right door size for your needs. Remember,
            if you're unsure, it's best to seek assistance from a professional
            to ensure correct dimensions.
          </p>
        </div>
      </div>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8  items-center justify-center ">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-3xl font-bold text-black t">
            To ensure accurate measurements of a prehung front door, you can
            follow these steps:
          </h1>
          <p className="mt-3 text-xl text-black bg-slate-100 px-6 text-justify">
            1. Begin by measuring the width of the prehung door from the
            interior, measuring from one side to the other of the door panel or
            the swinging part. <br /> 2. Determine the door height by measuring
            from the top of the door panel to the bottom edge of the door.{" "}
            <br />
            3. Measure the door thickness and door jamb width. For the door
            thickness, measure the edge of the door itself. To measure the door
            jamb depth, locate the wooden frame holding the door in place and
            measure its depth and height. This might involve removing a section
            of the surrounding trim. Remember not to include any trim in this
            measurement. <br />
            4. Next, measure the width and height of the door frame. Measure the
            inside edge of the trim from one side to the other, from inside your
            home. <br /> 5. To verify if your door is square and level, measure
            the door diagonally from one corner to the opposite corner in an X
            pattern and check if the measurements match. If they do, it
            indicates that your door is square. Use a level on the top and sides
            of the frame to ensure it is plumb. <br />
            6. If you have concerns about the door being warped or uneven, take
            three measurements for each aspect (e.g., top, middle, and bottom or
            left, center, and right) to assess any variations.
          </p>
        </div>
      </div>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8  items-center justify-center">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-3xl font-bold text-black t">
            To ensure accuracy, it is recommended to measure the front door and
            sidelights by following these steps:
          </h1>
          <p className="mt-3 text-xl text-black bg-slate-100 px-6 text-justify">
            1. Determine the width of the exterior door by measuring from one
            side of the door panel to the other. Likewise, measure the width of
            the sidelights by measuring the distance between the inside of the
            jamb on both sides. Keep a separate record of the measurements for
            the exterior door and sidelights. <br />
            2. Measure the door height by determining the distance from the top
            of the door panel to the bottom edge of the door. Additionally,
            measure the height of the sidelights by measuring the distance
            between the inside of the top jamb to the inside of the bottom jamb.
            Record these measurements separately. <br />
            3. Assess the exterior door thickness and jamb depth. Measure the
            door thickness by measuring the edge of the door. For the door jamb
            depth, measure the depth of the wooden frame that holds the door in
            place. This might require removing a section of the trim surrounding
            the door. Exclude the trim from this measurement. Record the values
            for each measurement. <br />
            4. Lastly, measure the width and height of the door frame by
            measuring the inside edge of the trim from one side to the other
            while inside your home. Record these measurements as well. <br />
            5. To verify if the door is square and level, measure the door
            diagonally from one corner to the opposite corner in an X pattern
            and check if the measurements match. If they do, it indicates that
            your door is square. You can also use a level on the top and sides
            of the frame to ensure it is plumb. <br />
            6. If you have concerns about the door being warped or uneven, take
            three measurements for each aspect (e.g., top, middle, and bottom or
            left, center, and right) to assess any variations.
          </p>
        </div>
      </div>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8  items-center justify-center">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-3xl font-bold text-black t">
            To accurately measure double doors, you can follow these steps:
          </h1>
          <p className="mt-3 text-xl text-black bg-slate-100 px-6 text-justify">
            1. Measure the width of your exterior double doors by starting on
            the outside of your home and measuring from one side to the other,
            spanning across both door panels. Record the width measurement.{" "}
            <br />
            2. Determine the height of your double doors by measuring from the
            top of the door panel to the bottom edge of the door. <br />
            3. Find the door thickness and door jamb width. Measure the door
            thickness by measuring the edge of each door panel. To measure the
            door jamb width, locate the wooden frame that holds the doors in
            place and measure its width and height. This might involve removing
            a section of the surrounding trim. Ensure that you do not include
            the trim in this measurement.
            <br /> 4. Next, measure the width and height of the door frame.
            Measure the inside edge of the trim from one side to the other, from
            inside your home. <br />
            5. To verify if your double doors are square and level, measure from
            one corner to the opposite corner in an X pattern and check if the
            measurements match. If they do, it indicates that your doors are
            square. Use a level on the top and sides of the frame to ensure it
            is plumb. <br />
            6. If you have concerns about the doors being warped or uneven, take
            three measurements for each aspect (e.g., top, middle, and bottom or
            left, center, and right) to assess any variations.
          </p>
        </div>
      </div>
      <div className="bg-white py-12 px-4 sm:px-6 lg:px-8  items-center justify-center">
        <div className="max-w-7xl mx-auto text-center sm:text-left">
          <h1 className="  text-3xl font-bold text-black t">
            To obtain accurate measurements for double doors with sidelights,
            you can follow these steps:
          </h1>
          <p className="mt-3 text-xl text-black bg-slate-100 px-6 text-justify">
            1. Measure the width of the exterior door by measuring from one side
            of the door panel to the other and record this measurement.
            Additionally, measure the combined width of both door panels and
            record that value. Measure the width of the sidelights by measuring
            the distance between the inside of the jamb on both sides, and
            record these measurements separately for the exterior door and
            sidelights. <br />2. Measure the height of the front door by measuring
            from the top of the door panel to the bottom edge of the door. Then
            measure the height of the sidelight by measuring the distance
            between the inside of the top jamb to the inside of the bottom jamb.
            Record these values separately. <br />3. Measure the exterior door
            thickness by measuring the edge of the door itself. To determine the
            door jamb depth, measure the depth of the wooden frame that holds
            the door in place. This may require removing a section of the trim
            surrounding the door. Exclude the trim from this measurement and
            record the values for each. <br />4. Finally, measure the width and height
            of the door frame by measuring the inside edge of the trim from one
            side to the other while inside your home. Record these measurements.
            <br />5. Check if your door is square and level by measuring from one
            corner to the opposite corner in an X pattern and verifying if the
            measurements match. If they do, it indicates that your door is
            square. Use a level on the top and sides of the frame to ensure it
            is plumb. <br />6. If you are concerned about the door being warped or
            uneven, take three measurements for each aspect (e.g., top, middle,
            and bottom or left, center, and right) to assess any variations.
          </p>
        </div>
      </div>
    </>
  );
};

export default Customization;
