import React, { Fragment, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import './faq.css'

import Container from "@material-ui/core/Container";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const Result = (props) => {
  return <p>Your message is sent successfully. We will contact You soon.</p>;
};

const Faq = () => {
  const [result, showResult] = useState();
  const form = useRef();
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_p7l3nur",
        "template_rs1isr6",
        form.current,
        "XcySmgUQwU5EjNGdn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showResult(true);
  };

  return (
    <Container className=" mt-[4rem]  mb-5 ">
      <Fragment>
        <section className="contact">
          <div className="contact-heading">
            <h2>Frequently Asked Questions</h2>
          </div>
          </section>
        <Accordion open={open === 1}>
          <AccordionHeader onClick={() => handleOpen(1)}>
            Do you have a catalog?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium text-base">
            Yes! You can
            <label htmlFor="my-modal-3" className="text-slate-400 cursor-pointer">
         
              &nbsp;Click Here &nbsp;
            </label>
            <input type="checkbox" id="my-modal-3" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box relative">
                <label htmlFor="my-modal-3" className=" absolute right-2 top-2 cursor-pointer">
                  ✕
                </label>
                <p className="py-4">
                      <div className="formf">
                        <h2 className="text-xl font-bold mt-0">
                          
                          To Download Catalog
                        </h2>

                        <form ref={form} onSubmit={sendEmail}>
                          <input
                            className="formmrug  border-black "
                            type="text"
                            name="user_name"
                            placeholder="Your Full Name"
                          />
                          <input
                            className="formmrug border-black"
                            type="email"
                            name="user_email"
                            placeholder="Email Id"
                          />

                          <textarea
                            name="message"
                            id=""
                            cols="10"
                            rows="5"
                            placeholder="Some message to company"
                          >
                            {" "}
                          </textarea>
                          <input
                            className=" bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-[6rem] scroll-smooth"
                            type="submit"
                            value="Send"
                          />
                          <div className="row">
                            {result ? <Result /> : null}
                          </div>
                        </form>
                      </div>{" "}
                    </p>
              </div>
            </div>
            to request a soft copy or you can check out our Design Ideas to
                see different designs Iron Doors. If you have further questions
                feel free to <a className="text-slate-400" href="https://wa.me/919371779757" target="_blank">Whatsapp us</a>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2}>
          <AccordionHeader onClick={() => handleOpen(2)}>
            Are iron doors good for cold climates?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium text-base">
            Yes. This is because iron doors are made with a special insulation
            which makes them perfect for colder climates. This insulation acts
            as a buffer to the outdoors, meaning that the cold air cannot enter
            the home and heating cannot escape through the doors. This also
            makes iron doors a more energy efficient option.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3}>
          <AccordionHeader onClick={() => handleOpen(3)}>
            How to clean an iron door?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium text-base">
            Cleaning an iron door is a very simple process. Every three months
            you should wipe and clean the door with Murphy’s oil. This should
            only take a few minutes and will keep your wrought iron door looking
            beautiful and clean.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 4}>
          <AccordionHeader onClick={() => handleOpen(4)}>
          Do these doors come with a warranty?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium text-base">
          Absolutely! In fact, Iron doors Limited Lifetime Warranty is the
                best of any iron door manufacturer. A Limited Lifetime Warranty
                on door structure and 2 years on the finish.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 5}>
          <AccordionHeader onClick={() => handleOpen(5)}>
          Can I put one of your doors  in my <br /> existing home?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium px-6">
          Yes! Our doors can be made to order. We can make our doors to
                the exact dimension of your existing door opening.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 6}>
          <AccordionHeader onClick={() => handleOpen(6)}>
          If the picture of the door I want on your website/catalog has a
              flat top, can I get the same door with an eyebrow?
          </AccordionHeader>
          <AccordionBody className="text-black font-medium text-base">
          Yes, we will make any door in any style that you want. Round
                top, eyebrow in square jam, flat top, transom, etc.
          </AccordionBody>
        </Accordion>
      </Fragment>
    </Container>
  );
};

export default Faq;
