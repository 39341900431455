
import React from "react";
import "../SingleProducts/singleProducts.css";
import { Link } from "react-router-dom";

import s1 from "../../assets/cutouts clean/double doors/1.png";
import s2 from"../../assets/cutouts clean/double doors/4.png";
import s3 from "../../assets/cutouts clean/double doors/6.png";
import s4 from "../../assets/cutouts clean/double doors/7.png";
import s5 from "../../assets/cutouts clean/double doors/8.png";
import s6 from "../../assets/cutouts clean/double doors/9.png";
import s7 from "../../assets/cutouts clean/double doors/10.png";
import s8 from "../../assets/cutouts clean/double doors/11.png";
import s9 from "../../assets/cutouts clean/double doors/12.png";
import s10 from "../../assets/cutouts clean/double doors/13.png";
import s12 from "../../assets/cutouts clean/double doors/15.png";
import s13 from "../../assets/cutouts clean/double doors/16.png" ;
import s14 from "../../assets/cutouts clean/double doors/17.png";
import s15 from "../../assets/cutouts clean/double doors/18.png";
import s16 from "../../assets/cutouts clean/double doors/19.png";







const DoubleProducts = () => {
  const products = [
  
    {
      id: 1,
      name: "Double Door 1",
      
      image:s1
    },
    {
      id: 2,
      name: "Double Door 2",
      
      image: s2,
    },

    {
      id: 3,
      name: "Double Door 3",
      image:s3,
    },
    {
      id: 4,
      name: "Double Door 4",
      image:s4,
    },
    {
      id: 5,
      name: "Double Door 5",
      image: s5,
    },
    {
      id: 6,
      name: "Double Door 6",
      image: s6,
    },
    {
      id: 7,
      name: "Double Door 7",
      image: s7,
    },
    {
      id: 8,
      name: "Double Door 8",
      image: s8,
    },
    {
      id: 9,
      name: "Double Door 9",
      image: s9,
    },
    {
      id: 10,
      name: "Double Door 10",
      image: s10,
    },
    
    {
      id: 12,
      name: "Double Door 10",
      image: s12,
    },
    {
      id: 13,
      name: "Double Door 10",
      image: s13,
    },
    {
      id: 14,
      name: "Double Door 14",
      image: s14,
    },
    {
      id: 15,
      name: "Double Door 15",
      image: s15,
    },
    {
      id: 16,
      name: "Double Door 15",
      image: s16,
    },


    // Add more products for other categories
  ];
  return (
    <>
<div className="sp mt-[4rem]">

  
<div className="cardssingle">
  {products.map((product) => (
    <div className="singlecard bg-base-100 shadow-xl">
      <figure className="h-30">
        <Link to={`/doubleproducts/${product.id}`}>
          <img
            className="singleimages"                 src={product.image}
            alt={product.name}
          />
        </Link>
      </figure>
      <div className="cardcon">
        <h2 className="stitle">{product.name}</h2>
      </div>
    </div>
  ))}
</div>
</div>
</>
  
  );
};

export default DoubleProducts;
