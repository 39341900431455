import React from "react";

const Footer = () => {
  return (
    <div className="mt-[3rem]">
      <footer className="footer footer-center p-10 bg-base-200 text-base-content rounded">
        <div className="grid grid-flow-col gap-4">
          <a href="/contact" className="link link-hover font-bold text-lg">
            Contact us{" "}
          </a>
          <a href="/faq" className="link link-hover font-bold text-lg">
        FAQ'S
          </a>
          <a href="/products" className="link link-hover font-bold text-lg">
            Products
          </a>
        </div>
        <div>
          <div className="grid grid-flow-col gap-4">
            <img
              className=" imgn h-[6rem] border-solid border-2 border-black "
              src={require("./../../assets/k.png")}
              alt="logo"
            />
          </div>
        </div>
        <div>
          <p>Copyright © 2023 - All right reserved by Amso Iron Works</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
