import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";

import Products from "./pages/Products/Products";
import Contact from "./pages/Contact/Contact";
import Payment from "./pages/Payment/Payment";

import { CrispProvider } from "./components/chatbot/crisp-provider";
import Faq from "./pages/Faq/Faq";
import DoubleDoors from "./pages/DoubleDoor/DoubleDoors";

import SingleProducts from "./pages/SingleProducts/SingleProducts";
import DoubleProducts from "./pages/DoubleProducts/DoubleProducts";
import SingleOne from "./pages/SIngleone/SingleOne";


import WineCellar from "./pages/WineCellar/WineCellar";
import WineCellarone from "./pages/Winecellarone/WineCellarone";
import IronGates from "./pages/Gates/IronGates";
import IronProducts from "./pages/Gates/IronProducts";
import Customization from "./pages/Customization/Customization";


const Layout = () => {
  return (
    <div className="iron1">
      {/* <Whatsapp /> */}
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      
      
      ,
      {
        path: "/singleproducts",
        element: <SingleProducts />,
      },
      {
        path: "/singleproducts/:singleId",
        element: <SingleOne />,
      },
      {
        path: "/doubleproducts",
        element: <DoubleProducts />,
      },
      {
        path: "/doubleproducts/:doubleId",
        element: <DoubleDoors />,
      },
 
      
     
  
      {
        path: "/winecellar",
        element: <WineCellar/>,
      },
      {
        path: "/winecellar/:wineId",
        element: <WineCellarone/>,
      },
      
      {
        path: "/irongates",
        element: <IronGates />,
      },

      {
        path: "/irongates/:gateId",
        element: <IronProducts/>,
      },
       {
        path: "/customization",
        element: <Customization/>,
      },


     
      
      
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <CrispProvider />
     
    </div>
  );
}

export default App;
