import React from "react";
import "./Drawings.css";
import draw1 from "./../../assets/draw1.png";
import draw2 from "./../../assets/draw2.jpg";
import draw3 from "./../../assets/draw3.jpg";

const Drawings = () => {
  return (
    <div className="relative mt-[3rem]">
      <img
        src="https://lirp.cdn-website.com/8f3e9c05/dms3rep/multi/opt/GettyImages-505723384-1920w.jpg"
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-gray-900 bg-opacity-75">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid lg:grid-cols-3 gap-[4rem] md:grid-cols-2 md:gap-4 sm:grid-cols-1  xs:grid-cols-1">
            <div className="card w-[22rem] h-[20rem]  image-full">
              <figure>
                
                <img className="z-10" src={draw2} alt="Shoes" />
              </figure>
              <div className="card-body">
                <h2 className="card-title z-30 mt-[-4rem] font-bold text-white">YOUR THOUGHT</h2>
              </div>
            </div>
            <div className="card w-[22rem] h-[20rem]  image-full">
              <figure>
                <img className="z-10" src={draw1} alt="Shoes" />
              </figure>
              <div className="card-body">
                <h2 className="card-title z-30 mt-[-4rem] font-bold text-white">TO YOUR DESIGN</h2>
              </div>
            </div>
            <div className="card w-[22rem] h-[20rem]  image-full">
              <figure>
                <img className="z-10" src={draw3} alt="Shoes" />
              </figure>
              <div className="card-body">
                <h2 className="card-title z-30 mt-[-4rem] font-bold text-white">TO YOUR BEAUTIFUL PLACE</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawings;
