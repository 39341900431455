import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";

import "./about.css";

import "swiper/css";
import "swiper/css/effect-fade";

import doorAnimation from "./../../assets/animation/94680-looping-blob-door.json";
import Lottie from "lottie-react";




const About = () => {
  return (
    <>
      <Container className=" mt-[4rem]  mb-5 bg-slate-100">
        <Fragment>
          <section className="contact mt-3">
            <div className="contact-heading">
              <h2>About Us</h2>
            </div>
            <div className="bg-slate-200">

       
            <div className="hero-content text-center ">
              <div className="max-w-4xl">
                <p className="font-medium  text-xl ">
                  Welcome to Amso Iron Works the premier iron works company
                  specializing in the creation of high-quality, hand-crafted
                  doors and custom-made gates. Our team of skilled metal workers
                  and iron forgers brings years of experience and expertise to
                  every project we undertake.
                </p>
              </div>
            </div>
            <div className="hero-content text-center">
              <div className="max-w-4xl">
                <p className="font-medium  text-xl ">
                  Established in 2020, Amso Impex has quickly become a trusted
                  name in the industry, known for our commitment to quality and
                  craftsmanship. Our team takes pride in every project, working
                  tirelessly to ensure that each piece we create is unique,
                  beautiful, and built to last.
                </p>
              </div>
            </div>
            <div className="hero-content text-center">
              <div className="max-w-4xl">
                <p className="font-medium  text-xl ">
                  At Amso Impex, we understand that no two projects are the
                  same. That's why we offer a wide range of services to meet the
                  unique needs of our clients. Whether you're looking for a
                  one-of-a-kind gate for your home or a custom door for your
                  business, our team can help bring your vision to life.
                </p>
              </div>
            </div>
            </div>
           
            <div className="mission flex flex-col-2 gap-3.5 justify-center mt-3">
              <div className="card w-96 bg-slate-200 adow-xl justify-center items-center  ">
                <div className="card-body justify-center">
                  <h2 className="card-title justify-center font-medium text-black">
                  "Excellence in Design. Perfection in Craftsmanship."
                  </h2>
                  <p className="text-black">
                  From the initial design consultation to the final
                        installation, our team is dedicated to providing
                        exceptional service and unparalleled craftsmanship. We
                        use only the finest materials and employ the latest
                        techniques to ensure that each piece we create is both
                        beautiful and functional.
                  </p>
                </div>
              </div>

              <div class="card w-96 bg-slate-200 shadow-xl text-md">
                <div class="card-body">
                  <h2 class="card-title justify-center font-medium text-black">
                  High quality projects for your satisfaction
                  </h2>
                  <p className="text-black">
                  If you value quality craftsmanship and relentless
                        attention to detail, Amso Impex iron works are the right
                        choice. Whether selecting one of our beautiful styles or
                        creating a new work of art, you will find our passion in
                        professional service, timeliness, and
                        uncompromising quality.
                  </p>
                </div>
              </div>
            </div>

            <div className="mission flex flex-col-2 gap-3.5 justify-center mt-3">
              <div className="card w-96 bg-slate-200 adow-xl justify-center items-center  ">
                <div className="card-body justify-center">
                  <h2 className="card-title justify-center font-medium text-black">
                    Our Mission{" "}
                  </h2>
                  <p className="text-black">
                    Select a standard configuration or draw your own. The
                    possibilities are only limited by your imagination. We
                    accept any type of reference ideas and are willing to turn
                    them into reality.
                  </p>
                </div>
              </div>

              <div class="card w-96 bg-slate-200 shadow-xl text-md">
                <div class="card-body">
                  <h2 class="card-title justify-center font-medium text-black">
                    What we Do
                  </h2>
                  <p className="text-black">
                    Custom-built and Quality iron doors. We bring 37 highly
                    skilled metal workers & iron forgers with a deep
                    understanding of forging iron.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3.5 mt-[2rem] justify-center">
              <div className="card w-96 bg-slate-200 adow-xl justify-center items-center  ">
                <div className="card-body justify-center">
                  <h2 className="card-title justify-center font-medium text-black">
                    Customer Service
                  </h2>
                  <p className="text-black">
                    Amso Impex strives to deliver the best in personal service.
                    We are committed to clients and providing for their needs.
                  </p>
                </div>
              </div>
              <div className="card w-96 bg-slate-200 adow-xl justify-center items-center  ">
                <div className="card-body justify-center">
                  <h2 className="card-title justify-center font-medium text-black">
                    Illustrative
                  </h2>
                  <p className="text-black">
                    We specialize in providing products custom-fit for any
                    remodeling project. Our doors and windows are manufactured
                    to match the specs of your existing openings for minimally
                    invasive remodeling and installation. As the world of your
                    thoughts is infinite, we suggest you the pre-rendered
                    representatives.
                  </p>
                </div>
              </div>
            </div>

         
            <Lottie
              className="h-[350px] ml-[3rem]"
              animationData={doorAnimation}
              loop={true}
            />
            <a
              className=" mt-4  bg-yellow-600 text-white hover:bg-black font-semibold  py-2 px-4  rounded"
              href="/products"
            >
              Explore More
            </a>
            <div className="hero-content text-center">
              <div className="max-w-4xl">
                <p className="font-medium  text-xl ">
                  Thank you for considering Amso Impex for your next project. We
                  look forward to working with you to create a one-of-a-kind
                  piece that you will cherish for years to come.
                </p>
              </div>
            </div>
          </section>
        </Fragment>
      </Container>

      
    </>
  );
};

export default About;
