import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import s1 from "../../assets/cutouts clean/single doors/27.png";
import s2 from "../../assets/cutouts clean/single doors/2.png";
import s3 from "../../assets/cutouts clean/single doors/14.png";
import s4 from "../../assets/cutouts clean/single doors/20.png";
import s5 from "../../assets/cutouts clean/single doors/21.png";
import s6 from "../../assets/cutouts clean/single doors/22.png";
import s7 from "../../assets/cutouts clean/single doors/23.png";
import s8 from "../../assets/cutouts clean/single doors/24.png";
import s9 from "../../assets/cutouts clean/single doors/25.png";
import s10 from "../../assets/cutouts clean/single doors/26.png";

import s11 from "../../assets/cutouts clean/single doors/28.png";
import s12 from "../../assets/cutouts clean/single doors/29.png";
import s13 from "../../assets/cutouts clean/single doors/30.png";
import s14 from "../../assets/cutouts clean/single doors/31.png";
import s15 from "../../assets/cutouts clean/single doors/32.png";

import emailjs from "emailjs-com";

const Result = (props) => {
  return <p>Your message is sent successfully. We will contact You soon.</p>;
};

const SingleOne = () => {
  const [result, showResult] = useState();

  const widthSelect = useRef(null);
  const heightSelect = useRef(null);
  const topStyleSelect = useRef(null);
  const pushPullSelect = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      name: event.target.name.value,
      email: event.target.email.value,
      width: widthSelect.current.value,
      height: heightSelect.current.value,
      topStyle: topStyleSelect.current.value,
      pushPull: pushPullSelect.current.value,
    };
    emailjs
      .send(
        "service_709lh7m",
        "template_6zqp4cb",
        templateParams,
        "LGhTVh4D3i17nlA6h"
      )

      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    event.target.reset();
    showResult(true);
  };

  const { singleId } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productData = [
          {
            id: 1,
            name: "Single Door 1",

            image: s1,
          },
          {
            id: 2,
            name: "Single Door 2",

            image: s2,
          },

          {
            id: 3,
            name: "Single Door 3",
            image: s3,
          },
          {
            id: 4,
            name: "Single Door 4",
            image: s4,
          },
          {
            id: 5,
            name: "Single Door 5",
            image: s5,
          },
          {
            id: 6,
            name: "Single Door 6",
            image: s6,
          },
          {
            id: 7,
            name: "Single Door 7",
            image: s7,
          },
          {
            id: 8,
            name: "Single Door 8",
            image: s8,
          },
          {
            id: 9,
            name: "Single Door 9",
            image: s9,
          },
          {
            id: 10,
            name: "Single Door 10",
            image: s10,
          },
          {
            id: 11,
            name: "Single Door 11",
            image: s11,
          },
          {
            id: 12,
            name: "Single Door 12",
            image: s12,
          },
          {
            id: 13,
            name: "Single Door 13",
            image: s13,
          },
          {
            id: 14,
            name: "Single Door 14",
            image: s14,
          },
          {
            id: 15,
            name: "Single Door 15",
            image: s15,
          },
        ];

        const selectedProduct = productData.find(
          (product) => product.id === parseInt(singleId)
        );

        if (selectedProduct) {
          setProduct(selectedProduct);
        } else {
          console.log("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [singleId]);

  if (!product) {
    return <div>Loading...</div>;
  }
  return (
    <div className="flex flex-col lg:flex-row items-center lg:items-start mt-[7rem]">
      <div className="w-full lg:w-1/2 p-4">
        <img className="lg:h-[45rem]" src={product.image} alt={product.name} />
      </div>
      <div className="w-full font-bold lg:w-1/2 p-4 mt-[8rem]">
        <h2 className="text-[30px]">{product.name}</h2>
        <p>
          <p className="text-gray-700 mb-4">
            <h3 className="text-md font-semibold">Enter the Details:</h3>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col justify-center items-center ">
                <input
                  type="text"
                  required
                  name="name"
                  placeholder="Full Name"
                  className="input input-bordered w-full max-w-xs "
                />{" "}
                <br />
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Email Id"
                  className="input input-bordered w-full max-w-xs "
                />
              </div>

              <h3 className="mt-3">Enter the width:</h3>
              <select
                ref={widthSelect}
                className="select select-bordered w-full max-w-xs"
              >
                <option disabled selected>
                  30 Inch Doorway
                </option>
                <option>30 Inch Doorway</option>
                <option>32 Inch Doorway</option>
                <option>34 Inch Doorway</option>
                <option>36 Inch Doorway</option>
                <option>40 Inch Doorway</option>
                <option>48 Inch Doorway</option>
                <option>56 Inch Doorway</option>
                <option>60 Inch Doorway</option>
              </select>

              <h3 className="mt-3">Enter the height:</h3>
              <select
                ref={heightSelect}
                className="select select-bordered w-full max-w-xs mt-3"
              >
                <option disabled selected>
                  {" "}
                  80 Inch tall Doorway
                </option>
                <option> 80 Inch tall Doorway</option>{" "}
                <option>82 Inch tall Doorway</option>{" "}
                <option>84 Inch tall Doorway</option>
                <option>90 Inch tall Doorway</option>
                <option>96 Inch tall Doorway</option>
              </select>

              <h3 className="mt-3">Top Style:</h3>
              <select
                ref={topStyleSelect}
                className="select select-bordered w-full max-w-xs mt-3"
              >
                <option disabled selected>
                  Square Top
                </option>
                <option> Square Top</option>
                <option>Round Top</option>
                <option>7 Inch tall Eyebrow arch</option>
                <option>11 Inch tall Eyebrow arch</option>
              </select>

              <h3 className="mt-3">Push or Pull top Open:</h3>
              <select
                ref={pushPullSelect}
                className="select select-bordered w-full max-w-xs mt-3"
              >
                <option disabled selected>
      
                  Pull to Open
                </option>
                <option> Pull to Open</option> <option>Push to Open</option>
              </select>
              <br />

              <button
                type="submit"
                className="mt-[2rem] cursor-pointer  bg-yellow-600 hover:bg-black text-white font-semibold py-2 px-4 border border-yellow-500 hover:border-transparent rounded"
              >
                Get a Quote
              </button>
              <div className="row">{result ? <Result /> : null}</div>
            </form>
          </p>
        </p>
      </div>
    </div>
  );
};

export default SingleOne;
