import React from "react";
import "./navbar.css";

const Navbar = () => {
  return (
    <header id="header">
      <div>
        <div class="container">
          <div class="header_left">
            <a class="logon" href="#">
              <img
                className=" imgn h-[6rem] border-solid border-2 border-black "
                src={require("./../../assets/k.png")}
                alt="logo"
              />
            </a>
          </div>
          <div class="header_right">
            <label for="menuTrigger" class="nav_ico">
              <i className="fa fa-bars text-black"></i>
            </label>
            <input id="menuTrigger" type="checkbox" name="" />
            <nav class="main_nav">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/products">Products</a>
                  <i class="fa fa-caret-down"></i>
                  <ul>
                    <li>
                      <a href="/singleproducts">Single Doors</a>
                    </li>
                    <li>
                      <a href="/doubleproducts">Double Doors</a>
                    </li>
                    <li>
                      <a href="/winecellar">Wine Cellar Doors</a>
                    </li>
                    <li>
                      <a href="/customization">Customized Doors</a>
                    </li>
                    <li>
                      <a href="/irongates">Iron railings, Handrails, <br /> Gates, and Fences</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                
                
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
