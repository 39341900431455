import React from "react";
import "./singleProducts.css";
import { Link } from "react-router-dom";
import s1 from "../../assets/cutouts clean/single doors/27.png";
import s2 from "../../assets/si2.png";
import s3 from "../../assets/cutouts clean/single doors/14.png";
import s4 from "../../assets/cutouts clean/single doors/20.png";
import s5 from "../../assets/cutouts clean/single doors/21.png";
import s6 from "../../assets/cutouts clean/single doors/22.png";
import s7 from "../../assets/cutouts clean/single doors/23.png";
import s8 from "../../assets/cutouts clean/single doors/24.png";
import s9 from "../../assets/cutouts clean/single doors/25.png";
import s10 from "../../assets/cutouts clean/single doors/26.png";

import s11 from "../../assets/cutouts clean/single doors/28.png";
import s12 from "../../assets/cutouts clean/single doors/29.png";
import s13 from "../../assets/cutouts clean/single doors/30.png";
import s14 from "../../assets/cutouts clean/single doors/31.png";
import s15 from "../../assets/cutouts clean/single doors/32.png";

const SingleProducts = () => {
  const products = [
    {
      id: 1,
      name: "Single Door 1",

      image: s1,
    },
    {
      id: 2,
      name: "Single Door 2",

      image: s2,
    },

    {
      id: 3,
      name: "Single Door 3",
      image: s3,
    },
    {
      id: 4,
      name: "Single Door 4",
      image: s4,
    },
    {
      id: 5,
      name: "Single Door 5",
      image: s5,
    },
    {
      id: 6,
      name: "Single Door 6",
      image: s6,
    },
    {
      id: 7,
      name: "Single Door 7",
      image: s7,
    },
    {
      id: 8,
      name: "Single Door 8",
      image: s8,
    },
    {
      id: 9,
      name: "Single Door 9",
      image: s9,
    },
    {
      id: 10,
      name: "Single Door 10",
      image: s10,
    },
    {
      id: 11,
      name: "Single Door 11",
      image: s11,
    },
    {
      id: 12,
      name: "Single Door 12",
      image: s12,
    },
    {
      id: 13,
      name: "Single Door 13",
      image: s13,
    },
    {
      id: 14,
      name: "Single Door 14",
      image: s14,
    },
    {
      id: 15,
      name: "Single Door 15",
      image: s15,
    },
  ];
  return (
    <>
      {/* <div className="sp mt-[4rem]">
      <section className="spc">
        <div className="cards grids">
          {products.map((product) => (
            <div className="spcard" key={product.id}>
              <div className="spimages">
                <Link to={`/singleproducts/${product.id}`}>
                  <img className="w-[10rem]" src={product.image} alt={product.name} />
                </Link>
              </div>
              <div className="spcard-conten">
              <h1 className="sp-heading">{product.name}</h1>
              </div>
            </div>
          ))}
        </div>
      </section>
      </div> */}
      <div className="sp mt-[4rem]">

  
      <div className="cardssingle">
        {products.map((product) => (
          <div className="singlecard bg-base-100 shadow-xl">
            <figure className="h-30">
              <Link to={`/singleproducts/${product.id}`}>
                <img
                  className="singleimages"                 src={product.image}
                  alt={product.name}
                />
              </Link>
            </figure>
            <div className="cardcon">
              <h2 className="stitle">{product.name}</h2>
            </div>
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default SingleProducts;
